import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import DBConnect from "../API/DBConnect";
import classes from "./css/EditMenu.module.css"
import MyButton from "./UI/MyButton/MyButton";
import {useHistory} from "react-router-dom";

const EditMenu = (state) => {
	let id;

	// const [faculty, setFaculty] = useState([]);
	// const [benefits, setBenefits] = useState([]);
	const [fetching, loading, error] = useFetching(async () => {
		const response = (await DBConnect.getStudent(id))[0];
		setStudent(response);
		if (error) console.log(error);
	});

	const [fetchingPost, loadingPost, errorPost] = useFetching(async () => {
		// console.log(student)
		setSaving(true);
		await DBConnect.setInfoStudent(student)
			.finally(() => {
				setTimeout(() => history.push('/'), 1500)
			});
		if (errorPost) console.log(errorPost);
	})

	const [fetchingDelete, loadingFetchingDelete, errorFetchingDelete] = useFetching(async () => {
		setSaving(true);
		await DBConnect.deleteStudent(student.id)
			.finally(() => {
				setTimeout(() => history.push('/'), 1500)
			});
		if (errorPost) console.log(errorPost);
	})

	// const [fetchingConst, loadingFetchingConst, errorFetchingConst] = useFetching(async ()=>{
	//     if (errorFetchingConst) {
	//         console.log(errorFetchingDelete)
	//         return
	//     }
	//     // const faculty = await DBConnect.getFaculty();
	//     // setFaculty(faculty.records)
	//     // const benefits = await DBConnect.getBenefits();
	//     // console.log(benefits)
	//     // setBenefits(benefits.records);
	// })

	const [student, setStudent] = useState({});
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		id = state.match.params.id;
		if (id > 0)
			fetching()
		else {
			setStudent({
				id: 0,
				room: state.location.room,
				name: "",
				gender: "MALE",
				phone_number: "",
				home_phone: "",
				faculty_id: 1,
				group_id: "",
				benefits_id: 1,
				date_of_birth: "",
				hobby: "",
				home_address: "",
				home_number: "",
				brsm: "0",
				trade_union: "0",
				job: "0",
				education_type: "1",
				mother_info: "",
				mother_phone: "",
				father_info: "",
				father_phone: ""
			})
		}
	}, [])

	const history = useHistory();

	const modalSend = () => {
		return (
			<div className={classes.edit_menu}>
				{loadingPost || loadingFetchingDelete ? <h1>
					Сохраняем данные
				</h1> : errorPost || errorFetchingDelete ? <h1>Произошла ошибка: {errorPost || errorFetchingDelete}</h1> :
					<h1>Данные сохранены</h1>}
			</div>
		)
	}

	return (
		<div>
			{(loading) ? <div className={classes.edit_menu}>
					<h1>Идет загрузка данных!</h1>
				</div>
				: saving ? modalSend() :
					<div className={classes.edit_menu}>
						<form className={classes.edit_form}>
							<div>
								<div className={classes.field}>
									<input type="text" name="name" value={student.name}
												 onChange={e => setStudent({...student, name: e.target.value})}/>
									<label htmlFor="name">ФИО</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="phone" value={student.phone_number}
												 onChange={e => setStudent({...student, phone_number: e.target.value})}/>
									<label htmlFor="phone">Телефон</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="room" value={student.room}
												 onChange={e => setStudent({...student, room: e.target.value})}/>
									<label htmlFor="room">Комната</label>
								</div>
								<div className={classes.field}>
									<label htmlFor="faculty">Факультет</label>
									<select name="faculty" value={student.faculty_id ? student.faculty_id : '1'}
													onChange={e => setStudent({...student, faculty_id: e.target.value})}>
										<option value="1">ФилФак</option>
										<option value="2">ФСПТ</option>
										<option value="3">ИстФак</option>
										<option value="4">ФДО</option>
										<option value="5">ФЭО</option>
										<option value="6">ФФВ</option>
										<option value="7">ФНО</option>
										<option value="8">ФМФ</option>
										<option value="9">ЕстФак</option>
										<option value="10">ИИО</option>
										<option value="11">ИП</option>
										<option value="12">ИПКиП</option>
									</select>
								</div>
								<div className={classes.field}>
									<input type="text" name="group" value={student.group_id}
												 onChange={e => setStudent({...student, group_id: e.target.value})}/>
									<label htmlFor="group">Группа</label>
								</div>
								<div className={classes.field}>
									<select name="gender" value={student.gender ? student.gender : 'male'}
													onChange={e => setStudent({...student, gender: e.target.value})}>
										<option value="MALE">МУЖ</option>
										<option value="FEMALE">ЖЕН</option>
									</select>
									<label htmlFor="gender">Пол</label>
								</div>
								<div className={classes.field}>
									<label htmlFor="faculty">Льготы</label>
									<select name="faculty" value={student.benefits_id ? student.benefits_id : '1'}
													onChange={e => setStudent({...student, benefits_id: e.target.value})}>
										<option value="1">ТМП</option>
										<option value="2">Полусирота</option>
										<option value="3">Сирота</option>
										<option value="4">Многодетная семья</option>
										<option value="5">ЧАЭС</option>
										<option value="6">Воспит. матерью-одиночкой</option>
										<option value="7">Инвалидность</option>
										<option value="8">2 до 18</option>
										<option value="9">2 студента в БГПУ</option>
										<option value="10">Иностранцы</option>
										<option value="11">Вид на жительство</option>
									</select>
								</div>
								<div className={classes.field}>
									<input type="date" name="birth" value={student.date_of_birth}
												 onChange={e => setStudent({...student, date_of_birth: e.target.value})}/>
									<label htmlFor="birth">Дата рождения</label>
								</div>
								<div className={classes.field}>
									<input type="checkbox" name="brsm" checked={!!+student.brsm}
												 onChange={e => setStudent({...student, brsm: +e.target.checked})}/>
									<label htmlFor="brsm">БРСМ</label>
								</div>
								<div className={classes.field}>
									<input type="checkbox" name="trade_union" checked={!!+student.trade_union}
												 onChange={e => setStudent({...student, trade_union: +e.target.checked})}/>
									<label htmlFor="trade_union">Профсоюз</label>
								</div>
								<div className={classes.field}>
									<input type="checkbox" name="works" checked={!!+student.job}
												 onChange={e => setStudent({...student, job: +e.target.checked})}/>
									<label htmlFor="works">Работает</label>
								</div>
								<div className={classes.field}>
									<select name="form_education" value={student.education_type}
													onChange={e => setStudent({...student, education_type: +e.target.value})}>
										<option value="1">Бесплатная</option>
										<option value="0">Платная</option>
									</select>
									<label htmlFor="form_education">Форма обучения</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="hobbies" value={student.hobby}
												 onChange={e => setStudent({...student, hobby: e.target.value})}/>
									<label htmlFor="hobbies">Обществ. нагрузка, увлечения</label>
								</div>
							</div>
							<div>
								<div className={classes.field}>
									<textarea name="home_address" cols="30" rows="5" value={student.home_address}
														onChange={e => setStudent({...student, home_address: e.target.value})}></textarea>
									<label htmlFor="home_address">Домашний адрес</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="home_phone" value={student.home_phone}
												 onChange={e => setStudent({...student, home_phone: e.target.value})}/>
									<label htmlFor="home_phone">Домашний телефон</label>
								</div>
								<div className={classes.field}>
									<textarea name="father_info" cols="30" rows="5" value={student.father_info}
														onChange={e => setStudent({...student, father_info: e.target.value})}></textarea>
									<label htmlFor="father_info">Информация об отце</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="father_phone" value={student.father_phone}
												 onChange={e => setStudent({...student, father_phone: e.target.value})}/>
									<label htmlFor="father_phone">Телефон отца</label>
								</div>
								<div className={classes.field}>
									<textarea name="mother_info" cols="30" rows="5" value={student.mother_info}
														onChange={e => setStudent({...student, mother_info: e.target.value})}></textarea>
									<label htmlFor="mother_info">Информация о матери</label>
								</div>
								<div className={classes.field}>
									<input type="text" name="mother_phone" value={student.mother_phone}
												 onChange={e => setStudent({...student, mother_phone: e.target.value})}/>
									<label htmlFor="mother_phone">Телефон матери</label>
								</div>
							</div>
						</form>
						<div className={classes.button_form}>
							<MyButton onClick={() => history.push('/')}>Назад</MyButton>
							<MyButton onClick={fetchingPost}>Сохранить</MyButton>
							<MyButton onClick={fetchingDelete}>Выселить</MyButton>
						</div>
					</div>
			}
		</div>
	);
};

export default EditMenu;