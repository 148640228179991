import axios from "axios";

export default class DBConnect {
	static url = 'https://hostel.thelax3p.beget.tech/apps/bspu/hostel/api/';

	static async getInfoRoom(method, num) {
		try {
			const {data} = await axios.get(this.url + 'students/get.php?' + method + '=' + num);
			return data;
		} catch (e) {
			console.log(e.message);
		}
	}

	static async getStudent(id) {
		try {
			const {data} = await axios.get(this.url + 'students/get.php?id=' + id);
			return data;
		} catch (e) {
			console.log(e.message);
		}
	}

	static async setInfoStudent(student) {
		console.log(student);
		const response = await axios.post(this.url + 'students/editStudent.php', student);
		console.log(response);
		return response;
	}

	static async deleteStudent(id) {
		return await axios.get(this.url + 'students/deleteStudent.php?id=' + id)
	}
}