import {useEffect, useState} from "react";
import InfoRoom from "./components/InfoRoom";
import './App.css';
import {
    Switch,
    Route, BrowserRouter, Redirect
} from "react-router-dom";
import EditMenu from "./components/EditMenu";
import {isMobile} from "react-device-detect";
import MobileSelectRoom from "./components/MobileSelectRoom";
import MobileInfoRoom from "./components/MobileInfoRoom";
import MobileEditStudent from "./components/MobileEditStudent";
import Login from "./components/Login";
import CheckAuth from "./components/CheckAuth";
import NotFound from "./components/NotFound";

function App() {
    // const [numRoom, setNumRoom] = useState(0);
    // const [access, setAccess] = useState(false);


    // useEffect(()=>{
    //     const result = CheckAuth.checkKey()
    //         .then((pro)=>setAccess(pro));
    // },[]);

    // if(!access){
    //     return (
    //         <BrowserRouter basename={'/zaselenie'}>
    //             <div className={'App'}>
    //                 <Switch>
    //                     <Route path={'/'} render={(props)=><Login access={{access:access,setAccess:e=>setAccess(e)}} {...props}/>}></Route>
    //                     <Route path={'*'}>
    //                         <NotFound></NotFound>
    //                     </Route>
    //                 </Switch>
    //             </div>
    //         </BrowserRouter>
    //     )
    // }
    //  if (isMobile)
    //     return (
    //         <BrowserRouter basename={'/zaselenie'}>
    //             <div className="App">
    //                 <Switch>
    //                     <Route exact path={`/`}>
    //                         <MobileSelectRoom></MobileSelectRoom>
    //                     </Route>
    //                     <Route path={`/info-room/:id`} component={MobileInfoRoom}></Route>
    //                     <Route path={`/edit/id:id`} component={ MobileEditStudent}></Route>
    //                     <Route path={'*'}>
    //                         <NotFound></NotFound>
    //                     </Route>
    //                 </Switch>
    //             </div>
    //         </BrowserRouter>
    //     );
    // else
        return (
            <BrowserRouter basename={'/'}>
                <div className="App">
                    <Switch>
                        <Route exact path={`/`}>
                             <InfoRoom/>
                        </Route>
                        <Route path={`/edit/id:id`} component={EditMenu}></Route>
                        <Route path={'*'}>
                            <NotFound></NotFound>
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        );
}

export default App;
