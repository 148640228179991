import React, {useEffect, useState} from 'react';
import MyButton from "./UI/MyButton/MyButton";
import MyInput from "./UI/MyInput/MyInput";
import classes from "./css/InfoRoom.module.css";
import DBConnect from "../API/DBConnect";
import {useFetching} from "../hooks/useFetching";
import {Link} from "react-router-dom";

const InfoRoom = (state) => {
	const [room, setRoom] = useState(null);
	const [displayRoom, setDisplayRoom] = useState(null);
	const rooms = [51, 62, 95, 106, 139, 150, 183, 194];
	const [list, setList] = useState(null);

	useEffect(() => {
		console.log(state)
	}, [list]);

	const createArray = (students) => {
		console.log(students);
		const result = [];
		result.length = rooms.indexOf(+room) === -1 ? 3 : 2;
		for (let i = 0; i < result.length; i++) {
			result[i] = {
				id: -i,
				name: 'Свободно'
			}
		}

		for (let i = 0; i < students.length; i++) {
			result[i] = students[i];
		}
		setList(result);
		setDisplayRoom(room);
	}

	const [fetching, loading, error] = useFetching(async () => {
		try {
			setList([]);
			const records = await DBConnect.getInfoRoom('room', room);
			const result = [];
			result.length = rooms.indexOf(+room) === -1 ? 3 : 2;
			for (let i = 0; i < result.length; i++) {
				result[i] = {
					id: -i,
					name: 'Свободно'
				}
			}
			for (let i = 0; i < records.length; i++) {
				result[i] = records[i]
			}
			setList(result);
			setDisplayRoom(room);
		} catch (e) {
			console.log(e.message)
			createArray([]);
		}
	});
	return (
		<div className={classes.info_room}>
			<div className={classes.form_getRoom}>
				<h1 className={classes.h1_getRoom}>Заселение БГПУ</h1>
				<MyInput type={'text'} placeholder={'Номер комнаты'} onChange={(e) => setRoom(e.target.value)}></MyInput>
				<MyButton onClick={() => fetching()}>Получить информацию</MyButton>
			</div>
			<div className={classes.form_selStudent}>
				<h1 className={classes.h1_selStudent}>{!list ? 'Выберите комнату' : `Комната ${displayRoom}`}</h1>
				<div className={classes.student_block}>
					{loading ? <h2 className={classes.headers_infoRoom}>Загрузка данных</h2> :
						list ? list.map((el, index) => <Link className={classes.headers_infoRoom} to={{
								pathname: `/edit/id${el.id > 0 ? el.id : 0}`,
								room: displayRoom,
							}} key={index}>{el.name}</Link>)
							: error ? <h2 className={classes.headers_infoRoom}>Ошибка</h2> : ''}
				</div>
			</div>
		</div>
	);
};

export default InfoRoom;