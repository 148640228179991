import React from 'react';
import MyButton from "./UI/MyButton/MyButton";
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div>
            <h1>Страница не существует</h1>
            <MyButton>
                <Link to={'/'}>
                    Домой
                </Link>
            </MyButton>
        </div>
    );
};

export default NotFound;